// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-interness-ecommerce-addon-src-pages-agb-js": () => import("./../../../node_modules/@interness/ecommerce-addon/src/pages/agb.js" /* webpackChunkName: "component---node-modules-interness-ecommerce-addon-src-pages-agb-js" */),
  "component---node-modules-interness-ecommerce-addon-src-pages-display-js": () => import("./../../../node_modules/@interness/ecommerce-addon/src/pages/display.js" /* webpackChunkName: "component---node-modules-interness-ecommerce-addon-src-pages-display-js" */),
  "component---node-modules-interness-ecommerce-addon-src-pages-versand-und-zahlung-js": () => import("./../../../node_modules/@interness/ecommerce-addon/src/pages/versand-und-zahlung.js" /* webpackChunkName: "component---node-modules-interness-ecommerce-addon-src-pages-versand-und-zahlung-js" */),
  "component---node-modules-interness-ecommerce-addon-src-pages-widerruf-js": () => import("./../../../node_modules/@interness/ecommerce-addon/src/pages/widerruf.js" /* webpackChunkName: "component---node-modules-interness-ecommerce-addon-src-pages-widerruf-js" */),
  "component---node-modules-interness-theme-sonora-src-interness-brands-addon-templates-brand-default-js": () => import("./../../../node_modules/@interness/theme-sonora/src/@interness/brands-addon/templates/brand/default.js" /* webpackChunkName: "component---node-modules-interness-theme-sonora-src-interness-brands-addon-templates-brand-default-js" */),
  "component---node-modules-interness-theme-sonora-src-interness-brands-addon-templates-brands-index-js": () => import("./../../../node_modules/@interness/theme-sonora/src/@interness/brands-addon/templates/brands/index.js" /* webpackChunkName: "component---node-modules-interness-theme-sonora-src-interness-brands-addon-templates-brands-index-js" */),
  "component---node-modules-interness-theme-sonora-src-pages-404-js": () => import("./../../../node_modules/@interness/theme-sonora/src/pages/404.js" /* webpackChunkName: "component---node-modules-interness-theme-sonora-src-pages-404-js" */),
  "component---node-modules-interness-theme-sonora-src-pages-kontakt-js": () => import("./../../../node_modules/@interness/theme-sonora/src/pages/kontakt.js" /* webpackChunkName: "component---node-modules-interness-theme-sonora-src-pages-kontakt-js" */),
  "component---node-modules-interness-theme-sonora-src-pages-thanks-js": () => import("./../../../node_modules/@interness/theme-sonora/src/pages/thanks.js" /* webpackChunkName: "component---node-modules-interness-theme-sonora-src-pages-thanks-js" */),
  "component---node-modules-interness-web-core-src-pages-datenschutz-social-mdx": () => import("./../../../node_modules/@interness/web-core/src/pages/datenschutz-social.mdx" /* webpackChunkName: "component---node-modules-interness-web-core-src-pages-datenschutz-social-mdx" */),
  "component---node-modules-interness-web-core-src-pages-info-js": () => import("./../../../node_modules/@interness/web-core/src/pages/---info.js" /* webpackChunkName: "component---node-modules-interness-web-core-src-pages-info-js" */),
  "component---node-modules-interness-web-core-src-templates-page-legal-js": () => import("./../../../node_modules/@interness/web-core/src/templates/page/legal.js" /* webpackChunkName: "component---node-modules-interness-web-core-src-templates-page-legal-js" */),
  "component---src-pages-event-js": () => import("./../../../src/pages/event.js" /* webpackChunkName: "component---src-pages-event-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */)
}

